import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const softwareAboutData: SoftwareAboutData[] = [
    { icon: 'pe-7s-light', title: 'Tailored solution', message: "Todolabels offers expertly crafted automation flows based on user experience. We value your feedback and ideas to help enhance your productivity." },
    { icon: 'pe-7s-portfolio', title: 'Easy to use', message: "Our flows provide predefined configurations, yet they are easily adaptable to suit your specific needs and preferences." },
    { icon: 'pe-7s-like2', title: 'Secure Data', message: "We prioritize your data privacy and security. To utilize todo applications with our app, you must authorize access, ensuring your data remains secure and protected." }
  ];

  interface SoftwareAboutData {
    icon: string; title: string; message: string;
  }

const About = () => {
    return (
        <React.Fragment>
            <section className="section software-about" id="about">
        <Container>
          <Row>
            {(softwareAboutData || [])?.map((item: SoftwareAboutData, index: number) => (
              <Col lg={4} key={index}>
                <div className="softwere-about-box text-center p-3">
                  <div className="softwere-about-icon">
                    <i className={item.icon} />
                  </div>
                  <h4 className="f-15 text-uppercase letter-spacing_2 mt-4">{item.title}</h4>
                  <p className="text-muted mt-4">{item.message}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

        </React.Fragment>
    )
}
export default About