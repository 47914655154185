import React, { useState } from 'react'
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';

const SignUp = () => {
    const [errorMsg, setErrorMsg] = useState('');

    const handleInputChange = (event: any) => {
        event.preventDefault();
        const form = event.target;
        const formData = new FormData(form);

        const email = formData.get('email');

        const validationError = validateForm(email);
        if (validationError) {
            setErrorMsg(validationError);
            return;
        }
        
        form.reset();
        setErrorMsg('');
    };

    const validateForm = (email: any) => {
        if (!email || email.trim() === '') {
            return 'Please enter your email.';
        }
        return '';
    };
    return (
        <React.Fragment>
            <section className="section" id="sign-up">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="home-title text-center">
                                <h2 className="heading-title-title letter-spacing_4 text-uppercase">Sign up</h2>
                                <p className="heading-title-desc text-muted mt-4">Please provide your email address to receive notifications when our initial flows are available. We also welcome your ideas for new flows or any feedback you may have on our product.
                                </p>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-5 pt-3 justify-content-center">
                        <Col lg={8}>
                            <div className="custom-form mt-4">
                                <Form onSubmit={handleInputChange} name="myForm" id="myForm">
                                    {errorMsg && <Alert variant="danger" className="error_message">{errorMsg}*</Alert>}
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className="my-3">
                                                <Form.Control name="email" id="email" type="email" placeholder="Your Email *" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <Form.Group className="my-3">
                                                <Form.Control name="comments" id="comments" as="textarea" rows={5} placeholder="Optional feedback" />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mt-3">
                                                <Button type="submit" id="submit" name="send" className="submitBnt btn btn-md btn-dark-custom">Sign up</Button>
                                                <div id="simple-msg"></div>
                                            </div>
                                        </Col>
                                    </Row>
                                   
                                </Form>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </React.Fragment>
    )
}

export default SignUp