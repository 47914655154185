import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Banner = () => {
  return (
    <React.Fragment>
      <section className="softwere-home" id="home">
        <div className="bg-overlay"></div>
        <div className="home-center">
          <div className="home-desc-center">
            <Container>
              <Row className="align-items-center">
                <Col lg={6}>
                  <div className="softwere-home-content text-white mt-4">
                    <h4 className="softwere-home-title text-uppercase line-height_1_4 letter-spacing_4">
                      Boost your productivity with label automation
                    </h4>
                    <p className="softwere-home-desc f-13 mt-4 mx-auto">
                      We can help you achieve your goals with our user-friendly
                      automation flows, focusing on harnessing the power of
                      labels and tags. Our solution seamlessly integrates with
                      popular todo applications and provides both "one-click"
                      setup and more advanced adjustments tailored to your
                      needs.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Banner;
