import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Accordions from "../../../components/Accordion";
import faq from "../../../assets/images/softwere-faq-img.png"

const Faq = () => {
  return (
    <React.Fragment>
      <section className="section book-faqs" id="faq">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                  Frequently Asked Questions
                </h2>
                </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-3 align-items-center">
            <Col lg={6}>
              <div className="book-faq-img mt-4 text-center">
                <img src={faq} className="img-fluid" alt="" />
              </div>
            </Col>
            <Accordions />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Faq;
