import React from 'react'
import { Accordion, Col } from 'react-bootstrap'

const Accordions: React.FC = () => {
    return (
        <React.Fragment>
            <Col lg={6}>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade active show" id="pills-genarel" role="tabpanel" aria-labelledby="pills-genarel-tab">

                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header> <h6> <span>01.</span> How much does it cost?</h6></Accordion.Header>
                                <Accordion.Body className='text-muted f-14 line-height_1_8 p-3'>
                                Our product is currently offered at no charge. However, there may be potential for nominal fees in the future, which will be determined based on usage and the associated infrastructure costs.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><h6> <span>02.</span>When can we expect the initial integrations?</h6></Accordion.Header>
                                <Accordion.Body className='text-muted f-14 line-height_1_8 p-3'>
                                We aim to have our initial integrations ready by the end of Q1 2024, starting with Todoist and the integrations mentioned earlier. However, if you have suggestions for additional automations that would enhance your experience, please don't hesitate to share them with us.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><h6> <span>03.</span>How can I raise my idea?</h6></Accordion.Header>
                                <Accordion.Body className='text-muted f-14 line-height_1_8 p-3'>
                                You can submit your ideas using the form provided in the Sign-Up section. Alternatively, feel free to contact us directly through our Twitter or Facebook accounts.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </Col>
        </React.Fragment>
    )
}

export default Accordions