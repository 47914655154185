import React from "react";
import { Container, Row, Col, Nav, Tab, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

// Image
import bg2 from "../../../assets/images/background/bg-2.jpg";
import bg8 from "../../../assets/images/background/bg-8.jpg";
import bg17 from "../../../assets/images/background/bg-17.jpg";
import todoist from "../../../assets/images/icons/todoist.png";
import useActiveLink from "../../../components/useActiveLink";

const Integrations = () => {
  const { handleLinkClick } = useActiveLink(".navbar-nav li.active");

  return (
    <React.Fragment>
      <section className="section interior-services" id="integrations">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                  Integrations
                </h2>
                <p className="heading-title-desc text-muted mt-4">
                  Our application will soon integrate with leading todo
                  applications. Sign up below to receive updates on additional
                  integrations as they become available.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center mt-4">
            <Col lg={8}>
              <div className="text-center">
                <Link
                  className="text-primary"
                  to="https://todoist.com/"
                  target="_blank"
                >
                  <img
                    src={todoist}
                    className="img-fluid rounded-circle"
                    style={{ height: "64px" }}
                    alt="todoist"
                  />
                </Link>
                <div className="justify-content-center mt-1 text-muted">
                  todoist: Q1 2024
                </div>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5">
            <Col lg={8}>
              <div className="text-center">
                <h2 className="heading-title-title letter-spacing_4 text-uppercase">
                  Flows
                </h2>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-4">
            <Col lg={12}>
              <Tab.Container id="pills-tab" defaultActiveKey="#planning">
                <Nav className="nav nav-tabs nav-pills mb-3" role="tablist">
                  <Nav.Item>
                    <Nav.Link eventKey="#planning">
                      <div className="interior-services-icon">
                        <i className="pe-7s-refresh"></i>
                      </div>
                      <h5 className="mb-0 text-uppercase f-15 letter-spacing_2 mt-3">
                        Next Action
                      </h5>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#interior">
                      <div className="interior-services-icon">
                        <i className="pe-7s-phone"></i>
                      </div>
                      <h5 className="mb-0 text-uppercase f-15 letter-spacing_2 mt-3">
                        Context
                      </h5>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="#exclusively">
                      <div className="interior-services-icon">
                        <i className="pe-7s-add-user"></i>
                      </div>
                      <h5 className="mb-0 text-uppercase f-15 letter-spacing_2 mt-3">
                        Your ideas
                      </h5>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content className="tab-content" id="pills-tabContent">
                  <Tab.Pane eventKey="#planning">
                    <div className="row mt-5">
                      <div className="col-lg-4">
                        <div className="interior-services-img mt-4">
                          <img src={bg8} className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="interior-services-info mt-4">
                          <h3 className="text-uppercase f-22 letter-spacing_4">
                            NEEDS NEXT ACTION
                          </h3>
                          <p className="text-muted mt-4">
                            Automatically apply the 'NeedsNextAction' label to a
                            task when its sub-task is completed.
                          </p>
                          <h4 className="text-uppercase f-14 letter-spacing_2">
                            Use case
                          </h4>
                          <p className="text-muted">
                            <li>
                              You are using one of your filters or the 'Today'
                              view.
                            </li>
                            <li>You complete the task.</li>
                            <li>
                              We tag the parent task with the 'NeedNextAction' label.
                            </li>
                            <li>
                              We generate an task to review items labeled 'NeedNextAction'.
                            </li>
                            <li>
                              You can easily find all the tasks that needs definion of next step.
                            </li>
                          </p>
                          <h4 className="text-uppercase f-14 letter-spacing_2">
                            Setup
                          </h4>
                          <p className="text-muted mt-4">
                            Enable the flow with a single click or customize it
                            to suit your preferences.
                            <li>Filter tasks by labels or project.</li>
                            <li>
                              Configure the creation of tasks for reviewing
                              items with the 'NeedNextAction' label.
                            </li>
                            <li>
                              Create a filter to review tasks labeled
                              'NeedNextAction'."
                            </li>
                          </p>
                          <h4 className="text-uppercase f-14 letter-spacing_2">
                            Integrations
                          </h4>
                          <p className="mt-2">
                            <img
                              src={todoist}
                              className="img-fluid rounded-circle"
                              style={{ height: "24px" }}
                              alt="todoist"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#interior">
                    <div className="row mt-5">
                      <div className="col-lg-4">
                        <div className="interior-services-img mt-4">
                          <img src={bg2} className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="interior-services-info mt-4">
                          <h3 className="text-uppercase f-22 letter-spacing_4">
                            MISSING CONTEXT
                          </h3>
                          <p className="text-muted mt-4">
                          Assign pre-defined labels to tasks for convenient context tracking, including factors such as size, location, and necessary individuals for progress
                          </p>
                          <h4 className="text-uppercase f-14 letter-spacing_2">
                            Use case
                          </h4>
                          <p className="text-muted">
                            <li>
                             You are using one of your filters or the 'Today' view to pick up next tasks to work on.
                            </li>
                            <li>We mark all the tasks that are missing context with "MissingContext" label.</li>
                            <li>
                              We generate an task to review items labeled 'MissingContext'.
                            </li>
                          </p>
                          <h4 className="text-uppercase f-14 letter-spacing_2">
                            Setup
                          </h4>
                          <p className="text-muted mt-4">
                            Enable the flow with a single click or customize it
                            to suit your preferences.
                            <li>Filter tasks by labels or project.</li>
                            <li>
                              Configure the creation of tasks for reviewing
                              items with the 'MissingContext' label.
                            </li>
                            <li>
                              Create a filter to review tasks labeled
                              'MissingContext'."
                            </li>
                          </p>
                          <h4 className="text-uppercase f-14 letter-spacing_2">
                            Integrations
                          </h4>
                          <p className="mt-2">
                            <img
                              src={todoist}
                              className="img-fluid rounded-circle"
                              style={{ height: "24px" }}
                              alt="todoist"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="#exclusively">
                    <div className="row mt-5">
                    <div className="col-lg-4">
                        <div className="interior-services-img mt-4">
                          <img src={bg17} className="img-fluid" alt="" />
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="interior-services-info mt-4">
                          <h3 className="text-uppercase f-22 letter-spacing_4">
                            Raise your idea
                          </h3>
                          <p className="text-muted mt-4">
                            We welcome your suggestions for new flows and value
                            feedback on existing ones. Please use the form below
                            to communicate easily with our team.
                          </p>
                          <div className="mt-4">
                            <Nav.Link
                              href="#sign-up"
                              onClick={() => handleLinkClick("/sign-up")}
                            >
                                <Button type="submit" id="submit" name="send" className="submitBnt btn btn-md btn-dark-custom"> CONTACT US</Button>
                            </Nav.Link>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Integrations;
