import React from 'react'
import Header from '../../layout/Header'
import Banner from './Sections/Banner'
import About from './Sections/About'
import Flows from './Sections/Integrations'
import SignUp from './Sections/SignUp'
import Faq from './Sections/Faq'

const MainPage = () => {
  return (
    <React.Fragment>
        <Header/>
        <Banner/>
        <About/>
        <Flows/>
        <Faq/>
        <SignUp/>
    </React.Fragment>
  )
}

export default MainPage